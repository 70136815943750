const VaccineTable = ({ requiredVaccines = [], recommendedVaccines = [] }) => {
  const allVaccines = [
    "COVID-19 Vaccine",
    "DTP Vaccine (Diptheria, Tetanus, Pertussis)",
    "Malaria Vaccine",
    "MMR Vaccine (Measles, Mumps, Rubella)",
    "Polio Vaccine",
    "Yellow Fever Vaccine"
  ]

  const cellStyle = { padding: '8px', textAlign: 'left' }

  return (
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th style={cellStyle}>Required</th>
          <th style={cellStyle}>Recommended</th>
          <th style={cellStyle}>Vaccine</th>
        </tr>
      </thead>
      <tbody>
        {allVaccines.map((vaccine) => (
          <tr key={vaccine}>
            <td style={cellStyle}>
              <span style={{
                width: '20px',
                height: '20px',
                border: '1px solid #000',
                display: 'inline-block',
                textAlign: 'center',
                lineHeight: '20px'
              }}>
                {requiredVaccines.includes(vaccine) ? '✔' : ''}
              </span>
            </td>
            <td style={cellStyle}>
              <span style={{
                width: '20px',
                height: '20px',
                border: '1px solid #000',
                display: 'inline-block',
                textAlign: 'center',
                lineHeight: '20px'
              }}>
                {recommendedVaccines.includes(vaccine) ? '✔' : ''}
              </span>
            </td>
            <td style={cellStyle}>{vaccine}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default VaccineTable;
