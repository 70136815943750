import EditDate from "../components/EditDate"
import VaccineTable from "../components/VaccineTable"
import { deCamelize, getLastUpdated } from "../functions/utils"
import FreeText from "./FreeText"

const MedicalInformation = ({ data, slug }) => {
    const date = getLastUpdated(null, data)
    return (
        <article id={slug}>
            <div className="flex-between-centered">
                <h3 className="content_h3">{deCamelize(slug)}</h3>
                <EditDate date={date} />
            </div>
            {data.map((el, i) => {
                if (!el || !el.fields) return
                const { internalName, mandatoryRequirements, vaxDisclaimer, vaccines, recommendedVaccines, mobilityMedical, generalLexMedicalForm, antarcticMedicalForm, additionalInfo } = el.fields
                return (
                    <div className="block-style" key={i}>
                    {internalName && <h3 style={{marginTop: 10, marginBottom: 30}} className="content_h3">{internalName}</h3>}
                        {mandatoryRequirements && (
                            <div>
                                <b>Mandatory Requirements</b>
                                <ul className="list-style" style={{marginTop: 10}}>
                                    {mandatoryRequirements.map(v => <li key={v}>{v}</li>)}
                                </ul>
                            </div>
                        )}
                        {vaxDisclaimer && (
                            <div style={{marginTop: 20}}>
                                <b>Vaccine Disclaimer</b>
                                <p>{vaxDisclaimer}</p>
                            </div>
                        )}
                        <VaccineTable requiredVaccines={vaccines} recommendedVaccines={recommendedVaccines} />
                        {mobilityMedical && (
                            <div style={{marginTop: 20}}>
                                <b>Mobility / Medical</b>
                                <ul className="list-style" style={{marginTop: 10}}>
                                    {mobilityMedical.map(v => <li key={v}>{v}</li>)}
                                </ul>
                            </div>
                        )}
                        {generalLexMedicalForm && (
                            <div className='d-flex-align-center' style={{marginTop: 20}}>
                                <b>General LEX Medical Form:</b>
                                <a className='link-wrap' href={generalLexMedicalForm} target='_blank'>{generalLexMedicalForm}</a>
                            </div>
                        )}
                        {antarcticMedicalForm && (
                            <div className='d-flex-align-center' style={{marginTop: 20}}>
                                <b>Antarctic Medical Form:</b>
                                <a className='link-wrap' href={antarcticMedicalForm} target='_blank'>{antarcticMedicalForm}</a>
                            </div>
                        )}
                        {additionalInfo && <FreeText data={additionalInfo} slug='additionalInfo' />}
                    </div>
                )
            })}
        </article>
     )
}

export default MedicalInformation